var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c("v-card-title", [
        _c("span", { staticClass: "secondary--text font-weight-bold" }, [
          _vm._v(
            _vm._s(_vm.secretLevels[_vm.level]) + " environment variables"
          ),
        ]),
      ]),
      _c(
        "v-card-text",
        [
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c("v-alert", { attrs: { type: "info", text: "", dense: "" } }, [
                _c("strong", [
                  _vm._v(
                    "Changes will take effect after the application is restarted."
                  ),
                ]),
              ]),
            ],
            1
          ),
          _vm.requestRunning === "get"
            ? _c("v-skeleton-loader", {
                staticClass: "mx-auto",
                attrs: { type: "table" },
              })
            : [
                _c(
                  "div",
                  { staticClass: "d-flex align-center" },
                  [
                    _vm.canAddEnvVar()
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "mr-2",
                            attrs: { color: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.openEnvVarDialog()
                              },
                            },
                          },
                          [
                            _c("v-icon", { attrs: { left: "" } }, [
                              _vm._v("add"),
                            ]),
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v("add new variable"),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("v-spacer"),
                    _c("v-text-field", {
                      staticClass: "mr-2",
                      attrs: {
                        label: "Search for an environment variable...",
                        autofocus: "",
                        "hide-details": "",
                        "prepend-inner-icon": "mdi-filter",
                        solo: "",
                        flat: "",
                        "background-color": "grey lighten-4",
                        dense: "",
                        clearable: "",
                      },
                      model: {
                        value: _vm.search,
                        callback: function ($$v) {
                          _vm.search = $$v
                        },
                        expression: "search",
                      },
                    }),
                    _c(
                      "v-btn",
                      {
                        attrs: { icon: "" },
                        on: {
                          click: function ($event) {
                            return _vm.fetchEnvVars()
                          },
                        },
                      },
                      [_c("v-icon", [_vm._v("refresh")])],
                      1
                    ),
                  ],
                  1
                ),
                _c("v-data-table", {
                  attrs: {
                    items: _vm.envvars,
                    "item-key": "evid",
                    headers: _vm.headers,
                    search: _vm.search,
                    options: _vm.tableOptions,
                    "footer-props": {
                      "items-per-page-options": _vm.itemsPerPageOptions,
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "top",
                        fn: function ({ pagination, options, updateOptions }) {
                          return [
                            _c("v-data-footer", {
                              staticClass: "no-border",
                              attrs: {
                                pagination: pagination,
                                options: options,
                                itemsPerPageOptions: _vm.itemsPerPageOptions,
                                "items-per-page-text":
                                  "$vuetify.dataTable.itemsPerPageText",
                              },
                              on: { "update:options": updateOptions },
                            }),
                          ]
                        },
                      },
                      {
                        key: "no-data",
                        fn: function () {
                          return [
                            _c("span", [
                              _vm._v(
                                "No environment variables have been added, yet. Click add new variable to create a new one."
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: `item.name`,
                        fn: function ({ item }) {
                          return [
                            _c("code", { staticClass: "text-body-1" }, [
                              _vm._v(_vm._s(item.name)),
                            ]),
                            item.overrides_secret_type
                              ? _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass: "ml-3",
                                                      attrs: {
                                                        color: "primary",
                                                        small: "",
                                                      },
                                                    },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    "mdi-information-outline"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "Overrides " +
                                          _vm._s(
                                            _vm.secretLevels[
                                              item.overrides_secret_type
                                            ].toLocaleLowerCase()
                                          ) +
                                          " secret"
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            item.env_var_type > _vm.level
                              ? _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass: "ml-3",
                                                      attrs: { small: "" },
                                                    },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    "mdi-information-outline"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "Defined for the " +
                                          _vm._s(
                                            _vm.secretLevels[
                                              item.env_var_type
                                            ].toLowerCase()
                                          )
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                      {
                        key: `item.value`,
                        fn: function ({ item }) {
                          return [
                            _c("code", { staticClass: "text-body-1" }, [
                              _vm._v(_vm._s(item.value)),
                            ]),
                          ]
                        },
                      },
                      {
                        key: `item.creator_name`,
                        fn: function ({ item }) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.last_editor_name || item.creator_name
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                      {
                        key: `item.creation_timestamp`,
                        fn: function ({ item }) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("dateTimeToHuman")(
                                    item.last_modified_timestamp ||
                                      item.creation_timestamp
                                  )
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                      {
                        key: `item.actions`,
                        fn: function ({ item }) {
                          return [
                            _c(
                              "div",
                              { staticClass: "d-flex flex-row justify-end" },
                              [
                                _c("copy-to-clipboard", {
                                  attrs: {
                                    textToCopy: item.name,
                                    buttonClass: "caption font-weight-bold",
                                    tooltip: "Copy name to clipboard",
                                    iconButton: true,
                                  },
                                }),
                                _c("copy-to-clipboard", {
                                  attrs: {
                                    textToCopy: item.value,
                                    buttonClass: "caption font-weight-bold",
                                    tooltip: "Copy value to clipboard",
                                    iconButton: true,
                                    icon: "mdi-text-box-multiple-outline",
                                  },
                                }),
                                _vm.canModifyEnvVar(item)
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          attrs: {
                                                            icon: "",
                                                            small: "",
                                                            color: "secondary",
                                                            loading:
                                                              _vm.selectedEnvVar
                                                                ?.srid ===
                                                                item.srid &&
                                                              _vm.requestRunning ===
                                                                "patch",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.openEnvVarDialog(
                                                                item
                                                              )
                                                            },
                                                          },
                                                        },
                                                        "v-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" },
                                                        },
                                                        [_vm._v("edit")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c("span", [
                                          _vm._v("Edit environment varialbe"),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.canModifyEnvVar(item)
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          attrs: {
                                                            icon: "",
                                                            small: "",
                                                            color: "secondary",
                                                            loading:
                                                              _vm.selectedEnvVar
                                                                ?.srid ===
                                                                item.srid &&
                                                              _vm.requestRunning ===
                                                                "delete",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.openDeleteDialog(
                                                                item
                                                              )
                                                            },
                                                          },
                                                        },
                                                        "v-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" },
                                                        },
                                                        [_vm._v("delete")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c("span", [
                                          _vm._v("Delete environment variable"),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ],
        ],
        2
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "600px",
            "lazy-validation": "",
            persistent: "",
          },
          model: {
            value: _vm.envVarDialog,
            callback: function ($$v) {
              _vm.envVarDialog = $$v
            },
            expression: "envVarDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm.selectedEnvVar
                  ? _c("span", [
                      _vm._v("Edit " + _vm._s(_vm.selectedEnvVar.name)),
                    ])
                  : _c("span", [_vm._v("Add an environment variable")]),
              ]),
              _c("v-card-subtitle", { staticClass: "caption" }, [
                _vm._v(
                  " This environment variable will be available in all applications of this " +
                    _vm._s(_vm.secretLevels[_vm.level].toLowerCase()) +
                    ". "
                ),
              ]),
              _c(
                "v-card-text",
                { staticClass: "mb-0" },
                [
                  _c(
                    "v-form",
                    {
                      model: {
                        value: _vm.envVarFormIsValid,
                        callback: function ($$v) {
                          _vm.envVarFormIsValid = $$v
                        },
                        expression: "envVarFormIsValid",
                      },
                    },
                    [
                      !_vm.selectedEnvVar
                        ? _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              hint: "This field is required",
                              rules: [
                                _vm.rules.required,
                                _vm.rules.validFirstChar,
                                _vm.rules.validChars,
                              ],
                              dense: "",
                              autofocus: !_vm.selectedEnvVar,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _vm._v("Type a name for your variable"),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              158921545
                            ),
                            model: {
                              value: _vm.newEnvVarName,
                              callback: function ($$v) {
                                _vm.newEnvVarName = $$v
                              },
                              expression: "newEnvVarName",
                            },
                          })
                        : _vm._e(),
                      _c("v-textarea", {
                        attrs: {
                          outlined: "",
                          rules: [_vm.rules.required],
                          dense: "",
                          hint: "This field is required",
                          autofocus: !!_vm.selectedEnvVar,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function () {
                              return [_vm._v("Type a value for your variable")]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.newEnvVarValue,
                          callback: function ($$v) {
                            _vm.newEnvVarValue = $$v
                          },
                          expression: "newEnvVarValue",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: { click: _vm.closeEnvVarDialog },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _vm.selectedEnvVar
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            text: "",
                            color: "primary",
                            disabled: !_vm.envVarFormIsValid,
                            loading: _vm.requestRunning === "patch",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.sendUpdateRequest()
                            },
                          },
                        },
                        [_vm._v(" Edit ")]
                      )
                    : _c(
                        "v-btn",
                        {
                          attrs: {
                            text: "",
                            color: "primary",
                            disabled: !_vm.envVarFormIsValid,
                            loading: _vm.requestRunning === "post",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.sendPostRequest()
                            },
                          },
                        },
                        [_vm._v(" Add ")]
                      ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }